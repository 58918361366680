import React from "react";
import { VscClose, VscListSelection } from "react-icons/vsc";
import { Link } from "react-router-dom";

export default function Navbar() {
  let menuOpen = false;

  const openMenu = () => {
    const menu = document.querySelector("#mobile-menu");
    menu.style.display = "flex";
    menuOpen = true;
  };

  const closeMenu = () => {
    const menu = document.querySelector("#mobile-menu");
    menu.style.display = "none";
    menuOpen = false;
  };
  console.log(menuOpen);

  return (
    <div
      id="navbar"
      className="w-full flex items-center justify-center border-b px-4 py-4 bg-gray-700 "
    >
      <div
        id="navbar-container"
        className="flex justify-between items-center w-full"
      >
        <div
          id="mega-brand-logo"
          className="flex justify-center items-center w-80"
        >
          <img
            src="../../mega-brand-logo.png"
            alt="mega-brand-logo"
            className="w-[120px] max-[768px]:w-[100px]"
          />
        </div>
        <div
          id="navbar-as-container"
          className="flex items-center justify-center max-[768px]:hidden w-[80%]"
        >
          <ul
            id="navbar-as-list"
            className="flex items-center justify-start gap-5 flex-wrap text-lg text-white"
          >
            <li className="py-1 ">
              <Link
                to="/"
                className=" transition delay-150 duration-150 ease-in-out hover:border-b-2 border-white hover:text-yellow-500"
              >
                HOME
              </Link>
            </li>
            <li className="py-1">
              <Link
                to="/shingai-boski"
                className=" transition delay-150 duration-150 ease-in-out hover:border-b-2 border-white hover:text-yellow-500"
              >
                SHINGAI BOSKI
              </Link>
            </li>
            {/* <li className="py-1">
              <Link
                to="/turkish-boski"
                className=" transition delay-150 duration-150 ease-in-out hover:border-b-2 border-white hover:text-yellow-500"
              >
                TURKISH BOSKI
              </Link>
            </li> */}
            <li className="py-1">
              <Link
                to="/j-tiktok-addition"
                className=" transition delay-150 duration-150 ease-in-out hover:border-b-2 border-white hover:text-yellow-500"
              >
                J.TikTok Addition
              </Link>
            </li>
            <li className="py-1">
              <Link
                to="/j-boski"
                className=" transition delay-150 duration-150 ease-in-out hover:border-b-2 border-white hover:text-yellow-500"
              >
                J.BOSKI
              </Link>
            </li>
            <li className="py-1">
              <Link
                to="/j-italian-silk"
                className=" transition delay-150 duration-150 ease-in-out hover:border-b-2 border-white hover:text-yellow-500"
              >
                J.ITALIAN SILK
              </Link>
            </li>
            <li className="py-1">
              <Link
                to="/bin-faisal-boski"
                className=" transition delay-150 duration-150 ease-in-out hover:border-b-2 border-white hover:text-yellow-500"
              >
                BIN-FAISAL BOSKI
              </Link>
            </li>
            <li className="py-1">
              <Link
                to="/bin-faisal-wnw"
                className=" transition delay-150 duration-150 ease-in-out hover:border-b-2 border-white hover:text-yellow-500"
              >
                BIN-FAISAL WASH & WEAR
              </Link>
            </li>
            <li className="py-1">
              <Link
                to="/gul-ahmad-mens"
                className=" transition delay-150 duration-150 ease-in-out hover:border-b-2 border-white hover:text-yellow-500"
              >
                GUL-AHMAD MENS
              </Link>
            </li>
            <li className="py-1">
              <Link
                to="/grace"
                className=" transition delay-150 duration-150 ease-in-out hover:border-b-2 border-white hover:text-yellow-500"
              >
                GRACE
              </Link>
            </li>
            <li className="py-1">
              <Link
                to="/grace-wnw"
                className=" transition delay-150 duration-150 ease-in-out hover:border-b-2 border-white hover:text-yellow-500"
              >
                GRACE WASH AND WEAR
              </Link>
            </li>
            <li className="py-1">
              <Link
                to="/all-in-one"
                className=" transition delay-150 duration-150 ease-in-out hover:border-b-2 border-white hover:text-yellow-500"
              >
                All IN ONE
              </Link>
            </li>
          </ul>
        </div>
        <div
          id="hamborger-icon-container"
          className="hidden max-[768px]:flex w-[20%]"
        >
          <button onClick={openMenu}>
            <VscListSelection size={28} color="white" />
          </button>
        </div>
        <div
          id="mobile-menu"
          className="absolute right-0 top-14 min-[561px]:top-8 bg-white  items-center justify-center w-1/2  hidden shadow-2xl z-50 "
        >
          <div
            id="mobile-menu-container"
            className="w-full flex flex-col items-center justify-center h-full  "
          >
            <div className="py-4 border-t  px-4 w-full ">
              <button onClick={closeMenu}>
                <VscClose size={28} />
              </button>
            </div>
            <ul
              id="mobile-navbar-as"
              className="flex flex-col justify-center items-start w-full text-lg shadow-2xl h-full"
            >
              <li className="py-4 border-t  px-4 w-full  hover:bg-slate-200 ">
                <Link onClick={closeMenu} to="/">
                  HOME
                </Link>
              </li>
              <li className="py-4 border-t  px-4 w-full  hover:bg-slate-200">
                <Link onClick={closeMenu} to="/shingai-boski">
                  SHINGAI BOSKI
                </Link>
              </li>
              {/* <li className="py-4 border-t  px-4 w-full  hover:bg-slate-200">
                <Link onClick={closeMenu} to="/turkish-boski">TURKISH BOSKI</Link>
              </li> */}
              <li className="py-4 border-t  px-4 w-full  hover:bg-slate-200">
                <Link onClick={closeMenu} to="/j-tiktok-addition">
                  J.TIKIOK ADDITION
                </Link>
              </li>
              <li className="py-4 border-t  px-4 w-full  hover:bg-slate-200">
                <Link onClick={closeMenu} to="/j-boski">
                  J.BOSKI
                </Link>
              </li>
              <li className="py-4 border-t  px-4 w-full  hover:bg-slate-200">
                <Link onClick={closeMenu} to="/j-italian-silk">
                  J.ITALIAN SILK
                </Link>
              </li>
              <li className="py-4 border-t  px-4 w-full  hover:bg-slate-200">
                <Link onClick={closeMenu} to="/bin-faisal-boski">
                  BIN-FAISAL BOSKI
                </Link>
              </li>
              <li className="py-4 border-t  px-4 w-full  hover:bg-slate-200">
                <Link onClick={closeMenu} to="/bin-faisal-wnw">
                  BIN-FAISAL WASH & WEAR
                </Link>
              </li>
              <li className="py-4 border-t  px-4 w-full  hover:bg-slate-200">
                <Link onClick={closeMenu} to="/gul-ahmad-mens">
                  GUL-AHMAD MENS
                </Link>
              </li>
              <li className="py-4 border-t  px-4 w-full  hover:bg-slate-200">
                <Link onClick={closeMenu} to="/grace">
                  GRACE
                </Link>
              </li>
              <li className="py-4 border-t  px-4 w-full  hover:bg-slate-200">
                <Link onClick={closeMenu} to="/grace-wnw">
                  GRACE WASH & WEAR
                </Link>
              </li>
              <li className="py-4 border-t border-b px-4 w-full  hover:bg-slate-200">
                <Link onClick={closeMenu} to="/all-in-one">
                  All In One
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
