import React from "react";

export default function Footer() {
  return (
    <div
      id="footer"
      className="w-full flex items-center justify-center border-t p-10 bg-black text-white text-lg font-semibold"
    >
      <div
        id="footer-container"
        className="flex items-center justify-center text-center flex-col gap-10"
      >
        <div id="footer-context">
          <a
            href={"/home"}
            className="transition delay-200 duration-200 ease-in-out  hover:border-b hover:border-white "
          >
            PRIVACY POLICY
          </a>
          <span> - </span>
          <a
            href={"/home"}
            className="transition delay-200 duration-200 ease-in-out  hover:border-b hover:border-white "
          >
            REFUND POLICY
          </a>
          <span> - </span>
          <a
            href={"/home"}
            className="transition delay-200 duration-200 ease-in-out  hover:border-b hover:border-white "
          >
            CONTACT INFORMATION
          </a>
          <span> - </span>
          <a
            href={"/home"}
            className="transition delay-200 duration-200 ease-in-out  hover:border-b hover:border-white "
          >
            TERMS AND SERVICES
          </a>
        </div>
        <div className="w-full flex items-center justify-center py-4 border-t">
          <span>© 2024,</span>
          <a
            href={"/home"}
            className="transition delay-200 duration-200 ease-in-out  hover:border-b hover:border-white "
          >
            MEGA BRANDS
          </a>
        </div>
      </div>
    </div>
  );
}
