import React from "react";
import { useNavigate } from "react-router-dom";
import { data } from "../Data/Data";

export default function AllInOne() {
  const navigate = useNavigate();

  const handleProductClick = (id) => {
    navigate(`/product/${id}`);
  };

  return (
    <div id="all-in-one">
      <div
        id="all-in-one-heading-container"
        className="w-full flex items-center justify-center max-[768px]:text-4xl text-5xl py-8 font-bold"
      >
        <span className="text-center">ALL IN ONE</span>
      </div>
      <div
        id="all-in-one-container"
        className="w-full flex items-center justify-center gap-10 flex-wrap py-10 "
      >
        {data.map((product) => {
          return (
            <div
              key={product.id}
              onClick={() => handleProductClick(product.id)}
              style={{ cursor: "pointer" }}
              id="all-in-one-card"
              className="flex items-center justify-start flex-col  max-[768px]:w-80 shadow  h-96  gap-4 w-64 transition delay-75 duration-200 ease-in-out hover:scale-110"
            >
              <div
                id="product-image"
                className="w-full h-[75%] max-[768px]:w-full"
              >
                <img
                  src={product.image}
                  alt="product-image"
                  className="w-full h-full"
                />
              </div>
              <div className="flex justify-center items-start flex-col text-base w-full h-[25%] px-4">
                <span className="">{product.name}</span>
                <span className="">{product.color}</span>
                <span className="text-red-700">{product.price}</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
