import React from "react";

export default function Headline() {
  return (
    <div
      id="headiline"
      className="flex items-center justify-center w-full bg-black"
    >
      <div
        id="headline-container"
        className="flex justify-center items-center text-base text-white py-1 flex-wrap text-center px-2"
      >
        Cash on Delivery & Free shipping All Over Pakistan 03154294970
      </div>
    </div>
  );
}
