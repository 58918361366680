export const data = [
  {
    id: 1,
    type: "bin-faisal-boski",
    name: "BIN FAISAL BOSKI ",
    cutPrice: "Rs 5,690.00",
    description:
      "BIN FASIAL'S Premium Boski is a top-quality fabric</br>Available in 2 Dominating Shades</br>Accessories Included</br>BIN FASIAL branded Bag Packing</br>BIN FAISAL branded Card</br>Hand Carry Fabric Brand tags,</br>brand label and ribbon</br>Fancy buttons</br>Reliable Stuff And Color.",
    image: "../../assets/Bin Faisal Boski/BFboski1.jpg",
    price: "Rs 2,700.00",
  },
  {
    id: 2,
    type: "bin-faisal-boski",
    name: "BIN FAISAL BOSKI ",
    cutPrice: "Rs 5,690.00",
    description:
      "BIN FASIAL'S Premium Boski is a top-quality fabric Available in 2 Dominating Shades Accessories Included BIN FASIAL branded Bag Packing BIN FAISAL branded Card Hand Carry Fabric Length 4 Meters Width 52-54 Inches Brand tags, brand label and ribbon Fancy buttons Reliable Stuff And Color 100% Guarantee of fabric, color fade, and return policy.",
    image: "../../assets/Bin Faisal Boski/BFboski2.jpg",
    price: "Rs 2,700.00",
  },
  {
    id: 3,
    type: "bin-faisal-wnw",
    cutPrice: "Rs 8,790.00",
    description:
      "BIN FAISAL</br>100% Pure Super Luxury Victory Wash & Wear</br>Accessories Included</br>BIN FASIAL branded box</br>BIN FASIAL branded card Hand Carry</br>Extra Cutting Available On demand</br>Brand tags, cards and ribbon Fancy buttons</br>Reliable Stuff And Color</br>Guarantee of fabric, color fade, and 100% return policy.",
    name: "BIN FAISAL (WASH n WEAR)",
    image: "../../assets/Bin Faisal WnW/BFwnw1.jpg",
    price: "Rs 3,050.00",
  },
  {
    id: 4,
    type: "bin-faisal-wnw",
    cutPrice: "Rs 8,790.00",
    description:
      "BIN FAISAL</br>100% Pure Super Luxury Victory Wash & Wear</br>Accessories Included</br>BIN FASIAL branded box</br>BIN FASIAL branded card Hand Carry</br>Extra Cutting Available On demand</br>Brand tags, cards and ribbon Fancy buttons</br>Reliable Stuff And Color</br>Guarantee of fabric, color fade, and 100% return policy.",
    name: "BIN FAISAL (WASH n WEAR)",
    image: "../../assets/Bin Faisal WnW/BFwnw2.jpg",
    price: "Rs 3,050.00",
  },
  {
    id: 5,
    type: "bin-faisal-wnw",
    cutPrice: "Rs 8,790.00",
    description:
      "BIN FAISAL</br>100% Pure Super Luxury Victory Wash & Wear</br>Accessories Included</br>BIN FASIAL branded box</br>BIN FASIAL branded card Hand Carry</br>Extra Cutting Available On demand</br>Brand tags, cards and ribbon Fancy buttons</br>Reliable Stuff And Color</br>Guarantee of fabric, color fade, and 100% return policy.",
    name: "BIN FAISAL (WASH n WEAR)",
    image: "../../assets/Bin Faisal WnW/BFwnw3.jpg",
    price: "Rs 3,050.00",
  },
  {
    id: 6,
    type: "bin-faisal-wnw",
    cutPrice: "Rs 8,790.00",
    description:
      "BIN FAISAL</br>100% Pure Super Luxury Victory Wash & Wear</br>Accessories Included</br>BIN FASIAL branded box</br>BIN FASIAL branded card Hand Carry</br>Extra Cutting Available On demand</br>Brand tags, cards and ribbon Fancy buttons</br>Reliable Stuff And Color</br>Guarantee of fabric, color fade, and 100% return policy.",
    name: "BIN FAISAL (WASH n WEAR)",
    image: "../../assets/Bin Faisal WnW/BFwnw4.jpg",
    price: "Rs 3,050.00",
  },
  {
    id: 7,
    type: "bin-faisal-wnw",
    cutPrice: "Rs 8,790.00",
    description:
      "BIN FAISAL</br>100% Pure Super Luxury Victory Wash & Wear</br>Accessories Included</br>BIN FASIAL branded box</br>BIN FASIAL branded card Hand Carry</br>Extra Cutting Available On demand</br>Brand tags, cards and ribbon Fancy buttons</br>Reliable Stuff And Color</br>Guarantee of fabric, color fade, and 100% return policy.",
    name: "BIN FAISAL (WASH n WEAR)",
    image: "../../assets/Bin Faisal WnW/BFwnw5.jpg",
    price: "Rs 3,050.00",
  },
  {
    id: 8,
    type: "bin-faisal-wnw",
    cutPrice: "Rs 8,790.00",
    description:
      "BIN FAISAL</br>100% Pure Super Luxury Victory Wash & Wear</br>Accessories Included</br>BIN FASIAL branded box</br>BIN FASIAL branded card Hand Carry</br>Extra Cutting Available On demand</br>Brand tags, cards and ribbon Fancy buttons</br>Reliable Stuff And Color</br>Guarantee of fabric, color fade, and 100% return policy.",
    name: "BIN FAISAL (WASH n WEAR)",
    image: "../../assets/Bin Faisal WnW/BFwnw6.jpg",
    price: "Rs 3,050.00",
  },
  {
    id: 9,
    type: "bin-faisal-wnw",
    cutPrice: "Rs 8,790.00",
    description:
      "BIN FAISAL</br>100% Pure Super Luxury Victory Wash & Wear</br>Accessories Included</br>BIN FASIAL branded box</br>BIN FASIAL branded card Hand Carry</br>Extra Cutting Available On demand</br>Brand tags, cards and ribbon Fancy buttons</br>Reliable Stuff And Color</br>Guarantee of fabric, color fade, and 100% return policy.",
    name: "BIN FAISAL (WASH n WEAR)",
    image: "../../assets/Bin Faisal WnW/BFwnw7.jpg",
    price: "Rs 3,050.00",
  },
  {
    id: 10,
    type: "bin-faisal-wnw",
    cutPrice: "Rs 8,790.00",
    description:
      "BIN FAISAL</br>100% Pure Super Luxury Victory Wash & Wear</br>Accessories Included</br>BIN FASIAL branded box</br>BIN FASIAL branded card Hand Carry</br>Extra Cutting Available On demand</br>Brand tags, cards and ribbon Fancy buttons</br>Reliable Stuff And Color</br>Guarantee of fabric, color fade, and 100% return policy.",
    name: "BIN FAISAL (WASH n WEAR)",
    image: "../../assets/Bin Faisal WnW/BFwnw8.jpg",
    price: "Rs 3,050.00",
  },
  {
    id: 11,
    type: "bin-faisal-wnw",
    cutPrice: "Rs 8,790.00",
    description:
      "BIN FAISAL</br>100% Pure Super Luxury Victory Wash & Wear</br>Accessories Included</br>BIN FASIAL branded box</br>BIN FASIAL branded card Hand Carry</br>Extra Cutting Available On demand</br>Brand tags, cards and ribbon Fancy buttons</br>Reliable Stuff And Color</br>Guarantee of fabric, color fade, and 100% return policy.",
    name: "BIN FAISAL (WASH n WEAR)",
    image: "../../assets/Bin Faisal WnW/BFwnw9.jpg",
    price: "Rs 3,050.00",
  },
  {
    id: 12,
    type: "bin-faisal-wnw",
    cutPrice: "Rs 8,790.00",
    description:
      "BIN FAISAL</br>100% Pure Super Luxury Victory Wash & Wear</br>Accessories Included</br>BIN FASIAL branded box</br>BIN FASIAL branded card Hand Carry</br>Extra Cutting Available On demand</br>Brand tags, cards and ribbon Fancy buttons</br>Reliable Stuff And Color</br>Guarantee of fabric, color fade, and 100% return policy.",
    name: "BIN FAISAL (WASH n WEAR)",
    image: "../../assets/Bin Faisal WnW/BFwnw10.jpg",
    price: "Rs 3,050.00",
  },
  {
    id: 13,
    type: "bin-faisal-wnw",
    cutPrice: "Rs 8,790.00",
    description:
      "BIN FAISAL</br>100% Pure Super Luxury Victory Wash & Wear</br>Accessories Included</br>BIN FASIAL branded box</br>BIN FASIAL branded card Hand Carry</br>Extra Cutting Available On demand</br>Brand tags, cards and ribbon Fancy buttons</br>Reliable Stuff And Color</br>Guarantee of fabric, color fade, and 100% return policy.",
    name: "BIN FAISAL (WASH n WEAR)",
    image: "../../assets/Bin Faisal WnW/BFwnw11.jpg",
    price: "Rs 3,050.00",
  },
  {
    id: 14,
    type: "bin-faisal-wnw",
    cutPrice: "Rs 8,790.00",
    description:
      "BIN FAISAL</br>100% Pure Super Luxury Victory Wash & Wear</br>Accessories Included</br>BIN FASIAL branded box</br>BIN FASIAL branded card Hand Carry</br>Extra Cutting Available On demand</br>Brand tags, cards and ribbon Fancy buttons</br>Reliable Stuff And Color</br>Guarantee of fabric, color fade, and 100% return policy.",
    name: "BIN FAISAL (WASH n WEAR)",
    image: "../../assets/Bin Faisal WnW/BFwnw12.jpg",
    price: "Rs 3,050.00",
  },
  {
    id: 15,
    type: "bin-faisal-wnw",
    cutPrice: "Rs 8,790.00",
    description:
      "BIN FAISAL</br>100% Pure Super Luxury Victory Wash & Wear</br>Accessories Included</br>BIN FASIAL branded box</br>BIN FASIAL branded card Hand Carry</br>Extra Cutting Available On demand</br>Brand tags, cards and ribbon Fancy buttons</br>Reliable Stuff And Color</br>Guarantee of fabric, color fade, and 100% return policy.",
    name: "BIN FAISAL (WASH n WEAR)",
    image: "../../assets/Bin Faisal WnW/BFwnw13.jpg",
    price: "Rs 3,050.00",
  },
  {
    id: 16,
    type: "bin-faisal-wnw",
    cutPrice: "Rs 8,790.00",
    description:
      "BIN FAISAL</br>100% Pure Super Luxury Victory Wash & Wear</br>Accessories Included</br>BIN FASIAL branded box</br>BIN FASIAL branded card Hand Carry</br>Extra Cutting Available On demand</br>Brand tags, cards and ribbon Fancy buttons</br>Reliable Stuff And Color</br>Guarantee of fabric, color fade, and 100% return policy.",
    name: "BIN FAISAL (WASH n WEAR)",
    image: "../../assets/Bin Faisal WnW/BFwnw14.jpg",
    price: "Rs 3,050.00",
  },
  {
    id: 17,
    type: "bin-faisal-wnw",
    cutPrice: "Rs 8,790.00",
    description:
      "BIN FAISAL</br>100% Pure Super Luxury Victory Wash & Wear</br>Accessories Included</br>BIN FASIAL branded box</br>BIN FASIAL branded card Hand Carry</br>Extra Cutting Available On demand</br>Brand tags, cards and ribbon Fancy buttons</br>Reliable Stuff And Color</br>Guarantee of fabric, color fade, and 100% return policy.",
    name: "BIN FAISAL (WASH n WEAR)",
    image: "../../assets/Bin Faisal WnW/BFwnw15.jpg",
    price: "Rs 3,050.00",
  },
  {
    id: 18,
    type: "bin-faisal-wnw",
    cutPrice: "Rs 8,790.00",
    description:
      "BIN FAISAL</br>100% Pure Super Luxury Victory Wash & Wear</br>Accessories Included</br>BIN FASIAL branded box</br>BIN FASIAL branded card Hand Carry</br>Extra Cutting Available On demand</br>Brand tags, cards and ribbon Fancy buttons</br>Reliable Stuff And Color</br>Guarantee of fabric, color fade, and 100% return policy.",
    name: "BIN FAISAL (WASH n WEAR)",
    image: "../../assets/Bin Faisal WnW/BFwnw16.jpg",
    price: "Rs 3,050.00",
  },
  {
    id: 19,
    type: "bin-faisal-wnw",
    cutPrice: "Rs 8,790.00",
    description:
      "BIN FAISAL</br>100% Pure Super Luxury Victory Wash & Wear</br>Accessories Included</br>BIN FASIAL branded box</br>BIN FASIAL branded card Hand Carry</br>Extra Cutting Available On demand</br>Brand tags, cards and ribbon Fancy buttons</br>Reliable Stuff And Color</br>Guarantee of fabric, color fade, and 100% return policy.",
    name: "BIN FAISAL (WASH n WEAR)",
    image: "../../assets/Bin Faisal WnW/BFwnw17.jpg",
    price: "Rs 3,050.00",
  },
  {
    id: 20,
    type: "bin-faisal-wnw",
    cutPrice: "Rs 8,790.00",
    description:
      "BIN FAISAL</br>100% Pure Super Luxury Victory Wash & Wear</br>Accessories Included</br>BIN FASIAL branded box</br>BIN FASIAL branded card Hand Carry</br>Extra Cutting Available On demand</br>Brand tags, cards and ribbon Fancy buttons</br>Reliable Stuff And Color</br>Guarantee of fabric, color fade, and 100% return policy.",
    name: "BIN FAISAL (WASH n WEAR)",
    image: "../../assets/Bin Faisal WnW/BFwnw18.jpg",
    price: "Rs 3,050.00",
  },
  {
    id: 21,
    type: "grace",
    description:
      "Brand GRACE</br>Winter Humdum wool stuff</br>Best quality Wool</br>Wrinkle less fabric</br>Original bags and tags</br>Guaranteed stuff",
    name: "Grace (Winter HumDum Wool)",
    image: "../../assets/Grace/grace1.jpg",
    price: "Rs 2,250.00",
  },
  {
    type: "grace",
    description:
      "Brand GRACE</br>Winter Humdum wool stuff</br>Best quality Wool</br>Wrinkle less fabric</br>Original bags and tags</br>Guaranteed stuff",
    id: 22,
    name: "Grace (Winter HumDum Wool)",
    image: "../../assets/Grace/grace2.jpg",
    price: "Rs 2,250.00",
  },
  {
    type: "grace",
    description:
      "Brand GRACE</br>Winter Humdum wool stuff</br>Best quality Wool</br>Wrinkle less fabric</br>Original bags and tags</br>Guaranteed stuff",
    id: 23,
    name: "Grace (Winter HumDum Wool)",
    image: "../../assets/Grace/grace3.jpg",
    price: "Rs 2,250.00",
  },
  {
    type: "grace",
    description:
      "Brand GRACE</br>Winter Humdum wool stuff</br>Best quality Wool</br>Wrinkle less fabric</br>Original bags and tags</br>Guaranteed stuff",
    id: 24,
    name: "Grace (Winter HumDum Wool)",
    image: "../../assets/Grace/grace4.jpg",
    price: "Rs 2,250.00",
  },
  {
    type: "grace",
    description:
      "Brand GRACE</br>Winter Humdum wool stuff</br>Best quality Wool</br>Wrinkle less fabric</br>Original bags and tags</br>Guaranteed stuff",
    id: 25,
    name: "Grace (Winter HumDum Wool)",
    image: "../../assets/Grace/grace5.jpg",
    price: "Rs 2,250.00",
  },
  {
    type: "grace",
    description:
      "Brand GRACE</br>Winter Humdum wool stuff</br>Best quality Wool</br>Wrinkle less fabric</br>Original bags and tags</br>Guaranteed stuff",
    id: 26,
    name: "Grace (Winter HumDum Wool)",
    image: "../../assets/Grace/grace6.jpg",
    price: "Rs 2,250.00",
  },
  {
    type: "grace",
    description:
      "Brand GRACE</br>Winter Humdum wool stuff</br>Best quality Wool</br>Wrinkle less fabric</br>Original bags and tags</br>Guaranteed stuff",
    id: 27,
    name: "Grace (Winter HumDum Wool)",
    image: "../../assets/Grace/grace7.jpg",
    price: "Rs 2,250.00",
  },
  {
    type: "grace",
    description:
      "Brand GRACE</br>Winter Humdum wool stuff</br>Best quality Wool</br>Wrinkle less fabric</br>Original bags and tags</br>Guaranteed stuff",
    id: 28,
    name: "Grace (Winter HumDum Wool)",
    image: "../../assets/Grace/grace8.jpg",
    price: "Rs 2,250.00",
  },
  {
    type: "grace",
    description:
      "Brand GRACE</br>Winter Humdum wool stuff</br>Best quality Wool</br>Wrinkle less fabric</br>Original bags and tags</br>Guaranteed stuff",
    id: 29,
    name: "Grace (Winter HumDum Wool)",
    image: "../../assets/Grace/grace9.jpg",
    price: "Rs 2,250.00",
  },
  {
    id: 30,
    type: "grace-wnw",
    description:
      "Grace brand</br>100% original</br>Color fabric guaranteed</br>Fabric  makhmal wash & wear</br>Wood botton</br>Color tag",
    name: "Grace Wash n Wear",
    color: "(Camel Color)",
    image: "../../assets/Grace WnW/gracewnw1.jpg",
    price: "Rs 2,250.00",
  },
  {
    id: 31,
    type: "grace-wnw",
    description:
      "Grace brand</br>100% original</br>Color fabric guaranteed</br>Fabric  makhmal wash wear</br>Wood botton</br>Color tag",
    name: "Grace Wash n Wear",
    color: "(Forest Green Color)",
    image: "../../assets/Grace WnW/gracewnw2.jpg",
    price: "Rs 2,250.00",
  },
  {
    id: 32,
    type: "grace-wnw",
    description:
      "Grace brand</br>100% original</br>Color fabric guaranteed</br>Fabric  makhmal wash wear</br>Wood botton</br>Color tag",
    name: "Grace Wash n Wear",
    color: "(Denim Blue Color)",
    image: "../../assets/Grace WnW/gracewnw3.jpg",
    price: "Rs 2,250.00",
  },
  {
    id: 33,
    type: "grace-wnw",
    description:
      "Grace brand</br>100% original</br>Color fabric guaranteed</br>Fabric  makhmal wash wear</br>Wood botton</br>Color tag",
    name: "Grace Wash n Wear",
    color: "(Navy Blue Color)",
    image: "../../assets/Grace WnW/gracewnw4.jpg",
    price: "Rs 2,250.00",
  },
  {
    id: 34,
    type: "grace-wnw",
    description:
      "Grace brand</br>100% original</br>Color fabric guaranteed</br>Fabric  makhmal wash wear</br>Wood botton</br>Color tag",
    name: "Grace Wash n Wear",
    color: "(Steel Grey Color)",
    image: "../../assets/Grace WnW/gracewnw5.jpg",
    price: "Rs 2,250.00",
  },
  {
    id: 35,
    type: "grace-wnw",
    description:
      "Grace brand</br>100% original</br>Color fabric guaranteed</br>Fabric  makhmal wash wear</br>Wood botton</br>Color tag",
    name: "Grace Wash n Wear",
    color: "(Indigo Color)",
    image: "../../assets/Grace WnW/gracewnw6.jpg",
    price: "Rs 2,250.00",
  },
  {
    id: 36,
    type: "grace-wnw",
    description:
      "Grace brand</br>100% original</br>Color fabric guaranteed</br>Fabric  makhmal wash wear</br>Wood botton</br>Color tag",
    name: "Grace Wash n Wear",
    color: "(Brick Red Color)",
    image: "../../assets/Grace WnW/gracewnw7.jpg",
    price: "Rs 2,250.00",
  },
  {
    id: 37,
    type: "grace-wnw",
    description:
      "Grace brand</br>100% original</br>Color fabric guaranteed</br>Fabric  makhmal wash wear</br>Wood botton</br>Color tag",
    name: "Grace Wash n Wear",
    color: "(Black Coffee Color)",
    image: "../../assets/Grace WnW/gracewnw8.jpg",
    price: "Rs 2,250.00",
  },
  {
    id: 38,
    type: "grace-wnw",
    description:
      "Grace brand</br>100% original</br>Color fabric guaranteed</br>Fabric  makhmal wash wear</br>Wood botton</br>Color tag",
    name: "Grace Wash n Wear",
    color: "(Dark Zink Color)",
    image: "../../assets/Grace WnW/gracewnw9.jpg",
    price: "Rs 2,250.00",
  },
  {
    id: 39,
    type: "grace-wnw",
    description:
      "Grace brand</br>100% original</br>Color fabric guaranteed</br>Fabric  makhmal wash wear</br>Wood botton</br>Color tag",
    name: "Grace Wash n Wear",
    color: "(Deep Purple Color)",
    image: "../../assets/Grace WnW/gracewnw10.jpg",
    price: "Rs 2,250.00",
  },
  {
    id: 40,
    type: "grace-wnw",
    description:
      "Grace brand</br>100% original</br>Color fabric guaranteed</br>Fabric  makhmal wash wear</br>Wood botton</br>Color tag",
    name: "Grace Wash n Wear",
    color: "(Coco Brown Color)",
    image: "../../assets/Grace WnW/gracewnw11.jpg",
    price: "Rs 2,250.00",
  },
  {
    id: 41,
    type: "grace-wnw",
    description:
      "Grace brand</br>100% original</br>Color fabric guaranteed</br>Fabric  makhmal wash wear</br>Wood botton</br>Color tag",
    name: "Grace Wash n Wear",
    color: "(Rose Wood Color)",
    image: "../../assets/Grace WnW/gracewnw12.jpg",
    price: "Rs 2,250.00",
  },
  {
    id: 42,
    type: "grace-wnw",
    description:
      "Grace brand</br>100% original</br>Color fabric guaranteed</br>Fabric  makhmal wash wear</br>Wood botton</br>Color tag",
    name: "Grace Wash n Wear",
    color: "(Gunmetal Color)",
    image: "../../assets/Grace WnW/gracewnw13.jpg",
    price: "Rs 2,250.00",
  },
  {
    id: 43,
    type: "gul-ahmad-mens",
    cutPrice: "Rs 3,500.00",
    description:
      "Gul Ahmad Men’s Luxury Four Season Collection 2024</br>Wide Range Of Color Available</br>Quality Premium Wash & Wear by Gul Ahmed</br>Available In 10 Colors</br>100% Pure Super Luxury Wash n wear</br>Same As Original Inlay card</br>Same As Original Stamp Included in Fabric</br>Same As Original Bag Included</br>Same As Original Tag Included</br>10 Fancy Button  Included",
    name: "GUL AHMAD MEN'S",
    color: "(PISTA)",
    image: "../../assets/Gul Ahmad Mens/GAM1.jpg",
    price: "Rs 2,650.00",
  },
  {
    id: 44,
    type: "gul-ahmad-mens",
    cutPrice: "Rs 3,500.00",
    description:
      "Gul Ahmad Men’s Luxury Four Season Collection 2024</br>Wide Range Of Color Available</br>Quality Premium Wash & Wear by Gul Ahmed</br>Available In 10 Colors</br>100% Pure Super Luxury Wash n wear</br>Same As Original Inlay card</br>Same As Original Stamp Included in Fabric</br>Same As Original Bag Included</br>Same As Original Tag Included</br>10 Fancy Button  Included",

    name: "GUL AHMAD MEN'S",
    color: "(TEA PINK)",
    image: "../../assets/Gul Ahmad Mens/GAM2.jpg",
    price: "Rs 2,650.00",
  },
  {
    type: "gul-ahmad-mens",
    cutPrice: "Rs 3,500.00",
    description:
      "Gul Ahmad Men’s Luxury Four Season Collection 2024</br>Wide Range Of Color Available</br>Quality Premium Wash & Wear by Gul Ahmed</br>Available In 10 Colors</br>100% Pure Super Luxury Wash n wear</br>Same As Original Inlay card</br>Same As Original Stamp Included in Fabric</br>Same As Original Bag Included</br>Same As Original Tag Included</br>10 Fancy Button  Included",
    id: 45,
    name: "GUL AHMAD MEN'S",
    color: "(ALMOND SKIN)",
    image: "../../assets/Gul Ahmad Mens/GAM3.jpg",
    price: "Rs 2,650.00",
  },
  {
    type: "gul-ahmad-mens",
    cutPrice: "Rs 3,500.00",
    description:
      "Gul Ahmad Men’s Luxury Four Season Collection 2024</br>Wide Range Of Color Available</br>Quality Premium Wash & Wear by Gul Ahmed</br>Available In 10 Colors</br>100% Pure Super Luxury Wash n wear</br>Same As Original Inlay card</br>Same As Original Stamp Included in Fabric</br>Same As Original Bag Included</br>Same As Original Tag Included</br>10 Fancy Button  Included",
    id: 46,
    name: "GUL AHMAD MEN'S",
    color: "(FAWN)",
    image: "../../assets/Gul Ahmad Mens/GAM4.jpg",
    price: "Rs 2,650.00",
  },
  {
    id: 47,
    type: "gul-ahmad-mens",
    cutPrice: "Rs 3,500.00",
    description:
      "Gul Ahmad Men’s Luxury Four Season Collection 2024</br>Wide Range Of Color Available</br>Quality Premium Wash & Wear by Gul Ahmed</br>Available In 10 Colors</br>100% Pure Super Luxury Wash n wear</br>Same As Original Inlay card</br>Same As Original Stamp Included in Fabric</br>Same As Original Bag Included</br>Same As Original Tag Included</br>10 Fancy Button  Included",
    name: "GUL AHMAD MEN'S",
    color: "(SKY BLUE)",
    image: "../../assets/Gul Ahmad Mens/GAM5.jpg",
    price: "Rs 2,650.00",
  },
  {
    type: "gul-ahmad-mens",
    cutPrice: "Rs 3,500.00",
    description:
      "Gul Ahmad Men’s Luxury Four Season Collection 2024</br>Wide Range Of Color Available</br>Quality Premium Wash & Wear by Gul Ahmed</br>Available In 10 Colors</br>100% Pure Super Luxury Wash n wear</br>Same As Original Inlay card</br>Same As Original Stamp Included in Fabric</br>Same As Original Bag Included</br>Same As Original Tag Included</br>10 Fancy Button  Included",
    id: 48,
    name: "GUL AHMAD MEN'S",
    color: "(PURE WHITE)",
    image: "../../assets/Gul Ahmad Mens/GAM6.jpg",
    price: "Rs 2,650.00",
  },
  {
    type: "gul-ahmad-mens",
    cutPrice: "Rs 3,500.00",
    description:
      "Gul Ahmad Men’s Luxury Four Season Collection 2024</br>Wide Range Of Color Available</br>Quality Premium Wash & Wear by Gul Ahmed</br>Available In 10 Colors</br>100% Pure Super Luxury Wash n wear</br>Same As Original Inlay card</br>Same As Original Stamp Included in Fabric</br>Same As Original Bag Included</br>Same As Original Tag Included</br>10 Fancy Button  Included",
    id: 49,
    name: "GUL AHMAD MEN'S",
    color: "(PASTLE BROWN)",
    image: "../../assets/Gul Ahmad Mens/GAM7.jpg",
    price: "Rs 2,650.00",
  },
  {
    type: "gul-ahmad-mens",
    cutPrice: "Rs 3,500.00",
    description:
      "Gul Ahmad Men’s Luxury Four Season Collection 2024</br>Wide Range Of Color Available</br>Quality Premium Wash & Wear by Gul Ahmed</br>Available In 10 Colors</br>100% Pure Super Luxury Wash n wear</br>Same As Original Inlay card</br>Same As Original Stamp Included in Fabric</br>Same As Original Bag Included</br>Same As Original Tag Included</br>10 Fancy Button  Included",
    id: 50,
    name: "GUL AHMAD MEN'S",
    color: "(CAMEL)",
    image: "../../assets/Gul Ahmad Mens/GAM8.jpg",
    price: "Rs 2,650.00",
  },
  {
    type: "gul-ahmad-mens",
    cutPrice: "Rs 3,500.00",
    description:
      "Gul Ahmad Men’s Luxury Four Season Collection 2024</br>Wide Range Of Color Available</br>Quality Premium Wash & Wear by Gul Ahmed</br>Available In 10 Colors</br>100% Pure Super Luxury Wash n wear</br>Same As Original Inlay card</br>Same As Original Stamp Included in Fabric</br>Same As Original Bag Included</br>Same As Original Tag Included</br>10 Fancy Button  Included",
    id: 51,
    name: "GUL AHMAD MEN'S",
    color: "(ROCK BLUE)",
    image: "../../assets/Gul Ahmad Mens/GAM9.jpg",
    price: "Rs 2,650.00",
  },
  {
    type: "gul-ahmad-mens",
    cutPrice: "Rs 3,500.00",
    description:
      "Gul Ahmad Men’s Luxury Four Season Collection 2024</br>Wide Range Of Color Available</br>Quality Premium Wash & Wear by Gul Ahmed</br>Available In 10 Colors</br>100% Pure Super Luxury Wash n wear</br>Same As Original Inlay card</br>Same As Original Stamp Included in Fabric</br>Same As Original Bag Included</br>Same As Original Tag Included</br>10 Fancy Button  Included",
    id: 52,
    name: "GUL AHMAD MEN'S",
    color: "(FROZEE)",
    image: "../../assets/Gul Ahmad Mens/GAM10.jpg",
    price: "Rs 2,650.00",
  },
  {
    id: 69,
    type: "j-boski",
    cutPrice: "",
    description:
      "J. Brand boski</br>Fabric winter</br>4 color Available</br>Color fabric guaranteed</br>100% original as packing",
    name: "J.Boski Winter Fabrics",
    color: "(OFF WHITE)",
    image: "../../assets/Junaid Jamshed Boski/JJboski1.jpg",
    price: "Rs 2,650.00",
  },
  {
    id: 70,
    type: "j-boski",
    description:
      "J. Brand boski</br>Fabric winter</br>4 color Available</br>Color fabric guaranteed</br>100% original as packing",
    name: "J.Boski Winter Fabrics",
    color: "(NAVY BLUE)",
    image: "../../assets/Junaid Jamshed Boski/JJboski2.jpg",
    price: "Rs 2,650.00",
  },
  {
    id: 71,
    type: "j-boski",
    description:
      "J. Brand boski</br>Fabric winter</br>4 color Available</br>Color fabric guaranteed</br>100% original as packing",
    name: "J.Boski Winter Fabrics",
    color: "(BLACK)",
    image: "../../assets/Junaid Jamshed Boski/JJboski3.jpg",
    price: "Rs 2,650.00",
  },
  {
    id: 72,
    type: "j-boski",
    description:
      "J. Brand boski</br>Fabric winter</br>4 color Available</br>Color fabric guaranteed</br>100% original as packing",
    name: "J.Boski Winter Fabrics",
    color: "(CREAM)",
    image: "../../assets/Junaid Jamshed Boski/JJboski4.jpg",
    price: "Rs 2,650.00",
  },
  {
    id: 74,
    type: "j-italian-silk",
    cutPrice: "Rs 4,799.00",
    description:
      "JUNAID JUMSHAID</br>Available in many Generous Shades</br>Package Includes</br>Environment Friendly Poly-Bag</br>Set of Metallic Buttons</br>Brand Inlays, Tags, Cards and Ribbon</br>Reliable Stuff And Color</br>100% Guarantee of fabric, color fade, and return policy.",
    name: "J.Italian Silk",
    color: "(OFF WHITE)",
    image: "../../assets/Junaid Jamshed IB/JJIB1.jpg",
    price: "Rs 2,050.00",
  },
  {
    id: 75,
    type: "j-italian-silk",
    cutPrice: "Rs 4,799.00",
    description:
      "JUNAID JUMSHAID</br>Available in many Generous Shades</br>Package Includes</br>Environment Friendly Poly-Bag</br>Set of Metallic Buttons</br>Brand Inlays, Tags, Cards and Ribbon</br>Reliable Stuff And Color</br>100% Guarantee of fabric, color fade, and return policy.",
    name: "J.Italian Silk",
    color: "(FOREST GREEN)",
    image: "../../assets/Junaid Jamshed IB/JJIB2.jpg",
    price: "Rs 2,050.00",
  },
  {
    id: 76,
    type: "j-italian-silk",
    cutPrice: "Rs 4,799.00",
    description:
      "JUNAID JUMSHAID</br>Available in many Generous Shades</br>Package Includes</br>Environment Friendly Poly-Bag</br>Set of Metallic Buttons</br>Brand Inlays, Tags, Cards and Ribbon</br>Reliable Stuff And Color</br>100% Guarantee of fabric, color fade, and return policy.",
    name: "J.Italian Silk",
    color: "(PURE WHITE)",
    image: "../../assets/Junaid Jamshed IB/JJIB3.jpg",
    price: "Rs 2,050.00",
  },
  {
    id: 77,
    type: "j-italian-silk",
    cutPrice: "Rs 4,799.00",
    description:
      "JUNAID JUMSHAID</br>Available in many Generous Shades</br>Package Includes</br>Environment Friendly Poly-Bag</br>Set of Metallic Buttons</br>Brand Inlays, Tags, Cards and Ribbon</br>Reliable Stuff And Color</br>100% Guarantee of fabric, color fade, and return policy.",
    name: "J.Italian Silk",
    color: "(LITE FEROZI)",
    image: "../../assets/Junaid Jamshed IB/JJIB4.jpg",
    price: "Rs 2,050.00",
  },
  {
    id: 78,
    type: "j-italian-silk",
    cutPrice: "Rs 4,799.00",
    description:
      "JUNAID JUMSHAID</br>Available in many Generous Shades</br>Package Includes</br>Environment Friendly Poly-Bag</br>Set of Metallic Buttons</br>Brand Inlays, Tags, Cards and Ribbon</br>Reliable Stuff And Color</br>100% Guarantee of fabric, color fade, and return policy.",
    name: "J.Italian Silk",
    color: "(BEIGE)",
    image: "../../assets/Junaid Jamshed IB/JJIB5.jpg",
    price: "Rs 2,050.00",
  },
  {
    id: 79,
    type: "j-italian-silk",
    cutPrice: "Rs 4,799.00",
    description:
      "JUNAID JUMSHAID</br>Available in many Generous Shades</br>Package Includes</br>Environment Friendly Poly-Bag</br>Set of Metallic Buttons</br>Brand Inlays, Tags, Cards and Ribbon</br>Reliable Stuff And Color</br>100% Guarantee of fabric, color fade, and return policy.",
    name: "J.Italian Silk",
    color: "(LITE GREY)",
    image: "../../assets/Junaid Jamshed IB/JJIB6.jpg",
    price: "Rs 2,050.00",
  },
  {
    id: 80,
    type: "j-italian-silk",
    cutPrice: "Rs 4,799.00",
    description:
      "JUNAID JUMSHAID</br>Available in many Generous Shades</br>Package Includes</br>Environment Friendly Poly-Bag</br>Set of Metallic Buttons</br>Brand Inlays, Tags, Cards and Ribbon</br>Reliable Stuff And Color</br>100% Guarantee of fabric, color fade, and return policy.",
    name: "J.Italian Silk",
    color: "(FLAMINGO)",
    image: "../../assets/Junaid Jamshed IB/JJIB7.jpg",
    price: "Rs 2,050.00",
  },
  {
    id: 81,
    type: "j-italian-silk",
    cutPrice: "Rs 4,799.00",
    description:
      "JUNAID JUMSHAID</br>Available in many Generous Shades</br>Package Includes</br>Environment Friendly Poly-Bag</br>Set of Metallic Buttons</br>Brand Inlays, Tags, Cards and Ribbon</br>Reliable Stuff And Color</br>100% Guarantee of fabric, color fade, and return policy.",
    name: "J.Italian Silk",
    color: "(ROSE BLIGE)",
    image: "../../assets/Junaid Jamshed IB/JJIB8.jpg",
    price: "Rs 2,050.00",
  },
  {
    id: 82,
    type: "j-italian-silk",
    cutPrice: "Rs 4,799.00",
    description:
      "JUNAID JUMSHAID</br>Available in many Generous Shades</br>Package Includes</br>Environment Friendly Poly-Bag</br>Set of Metallic Buttons</br>Brand Inlays, Tags, Cards and Ribbon</br>Reliable Stuff And Color</br>100% Guarantee of fabric, color fade, and return policy.",
    name: "J.Italian Silk",
    color: "(BUFF)",
    image: "../../assets/Junaid Jamshed IB/JJIB9.jpg",
    price: "Rs 2,050.00",
  },
  {
    id: 83,
    type: "j-italian-silk",
    cutPrice: "Rs 4,799.00",
    description:
      "JUNAID JUMSHAID</br>Available in many Generous Shades</br>Package Includes</br>Environment Friendly Poly-Bag</br>Set of Metallic Buttons</br>Brand Inlays, Tags, Cards and Ribbon</br>Reliable Stuff And Color</br>100% Guarantee of fabric, color fade, and return policy.",
    name: "J.Italian Silk",
    color: "(CREPE)",
    image: "../../assets/Junaid Jamshed IB/JJIB10.jpg",
    price: "Rs 2,050.00",
  },
  {
    id: 84,
    type: "j-italian-silk",
    cutPrice: "Rs 4,799.00",
    description:
      "JUNAID JUMSHAID</br>Available in many Generous Shades</br>Package Includes</br>Environment Friendly Poly-Bag</br>Set of Metallic Buttons</br>Brand Inlays, Tags, Cards and Ribbon</br>Reliable Stuff And Color</br>100% Guarantee of fabric, color fade, and return policy.",
    name: "J.Italian Silk",
    color: "(TUFTS BLUE)",
    image: "../../assets/Junaid Jamshed IB/JJIB11.jpg",
    price: "Rs 2,050.00",
  },
  {
    id: 85,
    type: "j-italian-silk",
    cutPrice: "Rs 4,799.00",
    description:
      "JUNAID JUMSHAID</br>Available in many Generous Shades</br>Package Includes</br>Environment Friendly Poly-Bag</br>Set of Metallic Buttons</br>Brand Inlays, Tags, Cards and Ribbon</br>Reliable Stuff And Color</br>100% Guarantee of fabric, color fade, and return policy.",
    name: "J.Italian Silk",
    color: "(ROUGE)",
    image: "../../assets/Junaid Jamshed IB/JJIB12.jpg",
    price: "Rs 2,050.00",
  },
  {
    id: 86,
    type: "j-italian-silk",
    cutPrice: "Rs 4,799.00",
    description:
      "JUNAID JUMSHAID</br>Available in many Generous Shades</br>Package Includes</br>Environment Friendly Poly-Bag</br>Set of Metallic Buttons</br>Brand Inlays, Tags, Cards and Ribbon</br>Reliable Stuff And Color</br>100% Guarantee of fabric, color fade, and return policy.",
    name: "J.Italian Silk",
    color: "(OLIVE)",
    image: "../../assets/Junaid Jamshed IB/JJIB13.jpg",
    price: "Rs 2,050.00",
  },
  {
    id: 87,
    type: "j-italian-silk",
    cutPrice: "Rs 4,799.00",
    description:
      "JUNAID JUMSHAID</br>Available in many Generous Shades</br>Package Includes</br>Environment Friendly Poly-Bag</br>Set of Metallic Buttons</br>Brand Inlays, Tags, Cards and Ribbon</br>Reliable Stuff And Color</br>100% Guarantee of fabric, color fade, and return policy.",
    name: "J.Italian Silk",
    color: "(BOSKI CREAM)",
    image: "../../assets/Junaid Jamshed IB/JJIB14.jpg",
    price: "Rs 2,050.00",
  },
  {
    id: 88,
    type: "j-italian-silk",
    cutPrice: "Rs 4,799.00",
    description:
      "JUNAID JUMSHAID</br>Available in many Generous Shades</br>Package Includes</br>Environment Friendly Poly-Bag</br>Set of Metallic Buttons</br>Brand Inlays, Tags, Cards and Ribbon</br>Reliable Stuff And Color</br>100% Guarantee of fabric, color fade, and return policy.",
    name: "J.Italian Silk",
    color: "(LITE PURPLE)",
    image: "../../assets/Junaid Jamshed IB/JJIB15.jpg",
    price: "Rs 2,050.00",
  },
  {
    id: 89,
    type: "j-tiktok-addition",
    description:
      "Men’s Luxury Winter Collection 2024</br>Brand JUNAID JAMSHED TIK TOK ADDITION</br>Quality Premium KHADDAR</br>100% Pure Super Premium Fancy KHADDAR</br>Same as Original Tag</br>Cuff Label tags Card and buttons</br>Same As Original Bag Included</br>Soft Quality</br>Available In  12 Colors",
    name: " JUNAID JAMSHED TIKTOK ADDITION ",
    image: "../../assets/Junaid Jamshed TTA/JJ1.jpg",
    price: "Rs 2,250.00",
  },
  {
    id: 90,
    type: "j-tiktok-addition",
    description:
      "Men’s Luxury Winter Collection 2024</br>Brand JUNAID JAMSHED TIK TOK ADDITION</br>Quality Premium KHADDAR</br>100% Pure Super Premium Fancy KHADDAR</br>Same as Original Tag</br>Cuff Label tags Card and buttons</br>Same As Original Bag Included</br>Soft Quality</br>Available In  12 Colors",
    name: " JUNAID JAMSHED TIKTOK ADDITION ",
    image: "../../assets/Junaid Jamshed TTA/JJ2.jpg",
    price: "Rs 2,250.00",
  },
  {
    id: 91,
    type: "j-tiktok-addition",
    description:
      "Men’s Luxury Winter Collection 2024</br>Brand JUNAID JAMSHED TIK TOK ADDITION</br>Quality Premium KHADDAR</br>100% Pure Super Premium Fancy KHADDAR</br>Same as Original Tag</br>Cuff Label tags Card and buttons</br>Same As Original Bag Included</br>Soft Quality</br>Available In  12 Colors",
    name: " JUNAID JAMSHED TIKTOK ADDITION ",
    image: "../../assets/Junaid Jamshed TTA/JJ3.jpg",
    price: "Rs 2,250.00",
  },
  {
    id: 92,
    type: "j-tiktok-addition",
    description:
      "Men’s Luxury Winter Collection 2024</br>Brand JUNAID JAMSHED TIK TOK ADDITION</br>Quality Premium KHADDAR</br>100% Pure Super Premium Fancy KHADDAR</br>Same as Original Tag</br>Cuff Label tags Card and buttons</br>Same As Original Bag Included</br>Soft Quality</br>Available In  12 Colors",
    name: " JUNAID JAMSHED TIKTOK ADDITION ",
    image: "../../assets/Junaid Jamshed TTA/JJ4.jpg",
    price: "Rs 2,250.00",
  },
  {
    id: 93,
    type: "j-tiktok-addition",
    description:
      "Men’s Luxury Winter Collection 2024</br>Brand JUNAID JAMSHED TIK TOK ADDITION</br>Quality Premium KHADDAR</br>100% Pure Super Premium Fancy KHADDAR</br>Same as Original Tag</br>Cuff Label tags Card and buttons</br>Same As Original Bag Included</br>Soft Quality</br>Available In  12 Colors",
    name: " JUNAID JAMSHED TIKTOK ADDITION ",
    image: "../../assets/Junaid Jamshed TTA/JJ5.jpg",
    price: "Rs 2,250.00",
  },
  {
    id: 94,
    type: "j-tiktok-addition",
    description:
      "Men’s Luxury Winter Collection 2024</br>Brand JUNAID JAMSHED TIK TOK ADDITION</br>Quality Premium KHADDAR</br>100% Pure Super Premium Fancy KHADDAR</br>Same as Original Tag</br>Cuff Label tags Card and buttons</br>Same As Original Bag Included</br>Soft Quality</br>Available In  12 Colors",
    name: " JUNAID JAMSHED TIKTOK ADDITION ",
    image: "../../assets/Junaid Jamshed TTA/JJ6.jpg",
    price: "Rs 2,250.00",
  },
  {
    id: 95,
    type: "j-tiktok-addition",
    description:
      "Men’s Luxury Winter Collection 2024</br>Brand JUNAID JAMSHED TIK TOK ADDITION</br>Quality Premium KHADDAR</br>100% Pure Super Premium Fancy KHADDAR</br>Same as Original Tag</br>Cuff Label tags Card and buttons</br>Same As Original Bag Included</br>Soft Quality</br>Available In  12 Colors",
    name: " JUNAID JAMSHED TIKTOK ADDITION ",
    image: "../../assets/Junaid Jamshed TTA/JJ7.jpg",
    price: "Rs 2,250.00",
  },
  {
    id: 96,
    type: "j-tiktok-addition",
    description:
      "Men’s Luxury Winter Collection 2024</br>Brand JUNAID JAMSHED TIK TOK ADDITION</br>Quality Premium KHADDAR</br>100% Pure Super Premium Fancy KHADDAR</br>Same as Original Tag</br>Cuff Label tags Card and buttons</br>Same As Original Bag Included</br>Soft Quality</br>Available In  12 Colors",
    name: " JUNAID JAMSHED TIKTOK ADDITION ",
    image: "../../assets/Junaid Jamshed TTA/JJ8.jpg",
    price: "Rs 2,250.00",
  },
  {
    id: 97,
    type: "j-tiktok-addition",
    description:
      "Men’s Luxury Winter Collection 2024</br>Brand JUNAID JAMSHED TIK TOK ADDITION</br>Quality Premium KHADDAR</br>100% Pure Super Premium Fancy KHADDAR</br>Same as Original Tag</br>Cuff Label tags Card and buttons</br>Same As Original Bag Included</br>Soft Quality</br>Available In  12 Colors",
    name: " JUNAID JAMSHED TIKTOK ADDITION ",
    image: "../../assets/Junaid Jamshed TTA/JJ9.jpg",
    price: "Rs 2,250.00",
  },
  {
    id: 98,
    type: "j-tiktok-addition",
    description:
      "Men’s Luxury Winter Collection 2024</br>Brand JUNAID JAMSHED TIK TOK ADDITION</br>Quality Premium KHADDAR</br>100% Pure Super Premium Fancy KHADDAR</br>Same as Original Tag</br>Cuff Label tags Card and buttons</br>Same As Original Bag Included</br>Soft Quality</br>Available In  12 Colors",
    name: " JUNAID JAMSHED TIKTOK ADDITION ",
    image: "../../assets/Junaid Jamshed TTA/JJ10.jpg",
    price: "Rs 2,250.00",
  },
  {
    id: 99,
    type: "j-tiktok-addition",
    description:
      "Men’s Luxury Winter Collection 2024</br>Brand JUNAID JAMSHED TIK TOK ADDITION</br>Quality Premium KHADDAR</br>100% Pure Super Premium Fancy KHADDAR</br>Same as Original Tag</br>Cuff Label tags Card and buttons</br>Same As Original Bag Included</br>Soft Quality</br>Available In  12 Colors",
    name: " JUNAID JAMSHED TIKTOK ADDITION ",
    image: "../../assets/Junaid Jamshed TTA/JJ11.jpg",
    price: "Rs 2,250.00",
  },
  {
    id: 135,
    type: "shingai-boski",
    name: "SHINGAI BOSKI",
    description:
      "Shingai Boski</br>With all kinds of guarantee of quality and price",
    color: "(BOSKI)",
    image: "../../assets/Shingai Boski/SB27.jpg",
    price: "Rs 1,450.00",
  },
  {
    id: 126,
    type: "shingai-boski",
    name: "SHINGAI BOSKI",
    description:
      "Shingai Boski</br>With all kinds of guarantee of quality and price",
    color: "(BOSKI)",
    image: "../../assets/Shingai Boski/SB28.jpg",
    price: "Rs 1,450.00",
  },
  {
    id: 127,
    type: "shingai-boski",
    name: "SHINGAI BOSKI",
    description:
      "Shingai Boski</br>With all kinds of guarantee of quality and price",
    color: "(OFF WHITE)",
    image: "../../assets/Shingai Boski/SB29.jpg",
    price: "Rs 1,450.00",
  },
  {
    id: 128,
    type: "shingai-boski",
    name: "SHINGAI BOSKI",
    description:
      "Shingai Boski</br>With all kinds of guarantee of quality and price",
    color: "(ROSE GOLD)",
    image: "../../assets/Shingai Boski/SB30.jpg",
    price: "Rs 1,450.00",
  },
  {
    id: 129,
    type: "shingai-boski",
    name: "SHINGAI BOSKI",
    description:
      "Shingai Boski</br>With all kinds of guarantee of quality and price",
    color: "(WHITE)",
    image: "../../assets/Shingai Boski/SB31.jpg",
    price: "Rs 1,450.00",
  },
  {
    id: 130,
    type: "shingai-boski",
    name: "SHINGAI BOSKI",
    description:
      "Shingai Boski</br>With all kinds of guarantee of quality and price",
    color: "(TEA PINK)",
    image: "../../assets/Shingai Boski/SB32.jpg",
    price: "Rs 1,450.00",
  },
  {
    id: 131,
    type: "shingai-boski",
    name: "SHINGAI BOSKI",
    description:
      "Shingai Boski</br>With all kinds of guarantee of quality and price",
    color: "(SILVER)",
    image: "../../assets/Shingai Boski/SB33.jpg",
    price: "Rs 1,450.00",
  },
  {
    id: 132,
    type: "shingai-boski",
    name: "SHINGAI BOSKI",
    description:
      "Shingai Boski</br>With all kinds of guarantee of quality and price",
    color: "(HOT PINK)",
    image: "../../assets/Shingai Boski/SB34.jpg",
    price: "Rs 1,450.00",
  },
  {
    id: 133,
    type: "shingai-boski",
    name: "SHINGAI BOSKI",
    description:
      "Shingai Boski</br>With all kinds of guarantee of quality and price",
    color: "(SEPIYA)",
    image: "../../assets/Shingai Boski/SB35.jpg",
    price: "Rs 1,450.00",
  },
  {
    id: 134,
    type: "shingai-boski",
    name: "SHINGAI BOSKI",
    description:
      "Shingai Boski</br>With all kinds of guarantee of quality and price",
    color: "(AQUA)",
    image: "../../assets/Shingai Boski/SB36.jpg",
    price: "Rs 1,450.00",
  },
  {
    id: 136,
    type: "shingai-boski",
    name: "SHINGAI BOSKI",
    description:
      "Shingai Boski</br>With all kinds of guarantee of quality and price",
    color: "(AQUA)",
    image: "../../assets/Shingai Boski/SB37.jpg",
    price: "Rs 1,450.00",
  },
  {
    id: 137,
    type: "shingai-boski",
    name: "SHINGAI BOSKI",
    description:
      "Shingai Boski</br>With all kinds of guarantee of quality and price",
    color: "(AQUA)",
    image: "../../assets/Shingai Boski/SB38.jpg",
    price: "Rs 1,450.00",
  },
  {
    id: 53,
    type: "shingai-boski",
    name: "SHINGAI BOSKI",
    description:
      "Shingai Boski</br>With all kinds of guarantee of quality and price",
    color: "(SKY)",
    image: "../../assets/Shingai Boski/SB8.jpg",
    price: "Rs 1,450.00",
  },
  {
    id: 54,
    type: "shingai-boski",
    name: "SHINGAI BOSKI",
    description:
      "Shingai Boski</br>With all kinds of guarantee of quality and price",
    color: "(WOOD LIGHT)",
    image: "../../assets/Shingai Boski/SB9.jpg",
    price: "Rs 1,450.00",
  },
  {
    id: 55,
    type: "shingai-boski",
    name: "SHINGAI BOSKI",
    description:
      "Shingai Boski</br>With all kinds of guarantee of quality and price",
    color: "(BLACK)",
    image: "../../assets/Shingai Boski/SB10.jpg",
    price: "Rs 1,450.00",
  },
  {
    id: 56,
    type: "shingai-boski",
    name: "SHINGAI BOSKI",
    description:
      "Shingai Boski</br>With all kinds of guarantee of quality and price",
    color: "(ZINK)",
    image: "../../assets/Shingai Boski/SB11.jpg",
    price: "Rs 1,450.00",
  },
  {
    id: 57,
    type: "shingai-boski",
    name: "SHINGAI BOSKI",
    description:
      "Shingai Boski</br>With all kinds of guarantee of quality and price",
    color: "(REDDISH MAROON)",
    image: "../../assets/Shingai Boski/SB12.jpg",
    price: "Rs 1,450.00",
  },
  {
    id: 58,
    type: "shingai-boski",
    name: "SHINGAI BOSKI",
    description:
      "Shingai Boski</br>With all kinds of guarantee of quality and price",
    color: "(ROYAL BLUE)",
    image: "../../assets/Shingai Boski/SB13.jpg",
    price: "Rs 1,450.00",
  },
  {
    id: 59,
    type: "shingai-boski",
    name: "SHINGAI BOSKI",
    description:
      "Shingai Boski</br>With all kinds of guarantee of quality and price",
    color: "(FOREST GREEN)",
    image: "../../assets/Shingai Boski/SB14.jpg",
    price: "Rs 1,450.00",
  },
  {
    id: 60,
    type: "shingai-boski",
    name: "SHINGAI BOSKI",
    description:
      "Shingai Boski</br>With all kinds of guarantee of quality and price",
    color: "(BROWN)",
    image: "../../assets/Shingai Boski/SB15.jpg",
    price: "Rs 1,450.00",
  },
  {
    id: 61,
    type: "shingai-boski",
    name: "SHINGAI BOSKI",
    description:
      "Shingai Boski</br>With all kinds of guarantee of quality and price",
    color: "(AMBER)",
    image: "../../assets/Shingai Boski/SB16.jpg",
    price: "Rs 1,450.00",
  },
  {
    id: 62,
    type: "shingai-boski",
    name: "SHINGAI BOSKI",
    description:
      "Shingai Boski</br>With all kinds of guarantee of quality and price",
    color: "(AMBER)",
    image: "../../assets/Shingai Boski/SB17.jpg",
    price: "Rs 1,450.00",
  },
  {
    id: 63,
    type: "shingai-boski",
    name: "SHINGAI BOSKI",
    description:
      "Shingai Boski</br>With all kinds of guarantee of quality and price",
    color: "(CHARCOAL GREY)",
    image: "../../assets/Shingai Boski/SB18.jpg",
    price: "Rs 1,450.00",
  },
  {
    id: 64,
    type: "shingai-boski",
    name: "SHINGAI BOSKI",
    description:
      "Shingai Boski</br>With all kinds of guarantee of quality and price",
    color: "(SEA GREEN)",
    image: "../../assets/Shingai Boski/SB19.jpg",
    price: "Rs 1,450.00",
  },
  {
    id: 65,
    type: "shingai-boski",
    name: "SHINGAI BOSKI",
    description:
      "Shingai Boski</br>With all kinds of guarantee of quality and price",
    color: "(MUSTARD)",
    image: "../../assets/Shingai Boski/SB20.jpg",
    price: "Rs 1,450.00",
  },
  {
    id: 66,
    type: "shingai-boski",
    name: "SHINGAI BOSKI",
    description:
      "Shingai Boski</br>With all kinds of guarantee of quality and price",
    color: "(MEHNDI)",
    image: "../../assets/Shingai Boski/SB21.jpg",
    price: "Rs 1,450.00",
  },
  {
    id: 67,
    type: "shingai-boski",
    name: "SHINGAI BOSKI",
    description:
      "Shingai Boski</br>With all kinds of guarantee of quality and price",
    color: "(MAROON)",
    image: "../../assets/Shingai Boski/SB22.jpg",
    price: "Rs 1,450.00",
  },
  {
    id: 68,
    type: "shingai-boski",
    name: "SHINGAI BOSKI",
    description:
      "Shingai Boski</br>With all kinds of guarantee of quality and price",
    color: "(CHOCOLATE)",
    image: "../../assets/Shingai Boski/SB23.jpg",
    price: "Rs 1,450.00",
  },
  {
    id: 100,
    type: "shingai-boski",
    name: "SHINGAI BOSKI",
    description:
      "Shingai Boski</br>With all kinds of guarantee of quality and price",
    color: "(SEA FOAM)",
    image: "../../assets/Shingai Boski/SB1.jpg",
    price: "Rs 1,450.00",
  },
  {
    id: 101,
    type: "shingai-boski",
    name: "SHINGAI BOSKI",
    description:
      "Shingai Boski</br>With all kinds of guarantee of quality and price",
    color: "(LIGHT PURPLE)",
    image: "../../assets/Shingai Boski/SB2.jpg",
    price: "Rs 1,450.00",
  },
  {
    id: 102,
    type: "shingai-boski",
    name: "SHINGAI BOSKI",
    description:
      "Shingai Boski</br>With all kinds of guarantee of quality and price",
    color: "(CEMENT)",
    image: "../../assets/Shingai Boski/SB3.jpg",
    price: "Rs 1,450.00",
  },
  {
    id: 103,
    type: "shingai-boski",
    name: "SHINGAI BOSKI",
    description:
      "Shingai Boski</br>With all kinds of guarantee of quality and price",
    color: "(LIME)",
    image: "../../assets/Shingai Boski/SB4.jpg",
    price: "Rs 1,450.00",
  },
  {
    id: 104,
    type: "shingai-boski",
    name: "SHINGAI BOSKI",
    description:
      "Shingai Boski</br>With all kinds of guarantee of quality and price",
    color: "(SAPPHIRE)",
    image: "../../assets/Shingai Boski/SB5.jpg",
    price: "Rs 1,450.00",
  },
  {
    id: 105,
    type: "shingai-boski",
    name: "SHINGAI BOSKI",
    description:
      "Shingai Boski</br>With all kinds of guarantee of quality and price",
    color: "(GREY)",
    image: "../../assets/Shingai Boski/SB6.jpg",
    price: "Rs 1,450.00",
  },
  {
    id: 106,
    type: "shingai-boski",
    name: "SHINGAI BOSKI",
    description:
      "Shingai Boski</br>With all kinds of guarantee of quality and price",
    color: "(BISCUIT)",
    image: "../../assets/Shingai Boski/SB7.jpg",
    price: "Rs 1,450.00",
  },
  {
    id: 123,
    type: "shingai-boski",
    name: "SHINGAI BOSKI",
    description:
      "Shingai Boski</br>With all kinds of guarantee of quality and price",
    color: "(NEVY)",
    image: "../../assets/Shingai Boski/SB24.jpg",
    price: "Rs 1,450.00",
  },
  {
    id: 123,
    type: "shingai-boski",
    name: "SHINGAI BOSKI",
    description:
      "Shingai Boski</br>With all kinds of guarantee of quality and price",
    color: "(NEVY)",
    image: "../../assets/Shingai Boski/SB25.jpg",
    price: "Rs 1,450.00",
  },
  {
    id: 124,
    type: "shingai-boski",
    name: "SHINGAI BOSKI",
    description:
      "Shingai Boski</br>With all kinds of guarantee of quality and price",
    color: "(CAMEL)",
    image: "../../assets/Shingai Boski/SB26.jpg",
    price: "Rs 1,450.00",
  },
 
];
