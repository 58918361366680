import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { data } from "../Data/Data";

export default function Home() {
  const navigate = useNavigate();

  const handleProductClick = (id) => {
    navigate(`/product/${id}`);
  };

  return (
    <div id="home">
      <div id="home-container">
        <div
          id="home-page-heading"
          className="flex w-full items-center justify-center flex-col py-8"
        >
          <span className=" max-[768px]:text-4xl text-5xl font-bold">
            MOST POPULAR
          </span>
          <span className="text-3xl border-b border-black">HOME PAGE</span>
        </div>
        <div
          id="home-products-container"
          className="w-full flex items-center justify-center gap-10 flex-wrap py-10 "
        >
          {data.map((product) => {
            if (product.type === "shingai-boski") {
              return (
                <div
                  key={product.id}
                  onClick={() => handleProductClick(product.id)}
                  style={{ cursor: "pointer" }}
                  id="home-card"
                  className="flex items-center justify-start flex-col  max-[768px]:w-80 shadow  h-96  gap-4 w-64 transition delay-75 duration-200 ease-in-out hover:scale-110"
                >
                  <div
                    id="product-image"
                    className="w-full h-[75%] max-[768px]:w-full"
                  >
                    <img
                      src={product.image}
                      alt="product-image"
                      className=" h-full w-full"
                    />
                  </div>
                  <div className="flex justify-center items-start flex-col text-base w-full h-[25%] px-4">
                    <span className="w-full max-h-full">
                      {product.name} <br /> (PREMIUM FABRIC)
                    </span>
                    <span className="text-red-700">{product.price}</span>
                  </div>
                </div>
              );
            }
          })}
        </div>
        <div
          id="all-in-one-button-container"
          className="w-full flex items-center justify-center py-8"
        >
          <button className="py-4 px-8 bg-black text-white transition delay-200 duration-200 ease-in-out  hover:text-black hover:outline hover:bg-transparent hover:outline-black">
            <Link to="/all-in-one" className="text-lg">
              VIEW ALL
            </Link>
          </button>
        </div>
      </div>
    </div>
  );
}
