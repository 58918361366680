import React from "react";
import { Link, useParams } from "react-router-dom";
import { data } from "../Data/Data";
import { FaWhatsapp } from "react-icons/fa";

const ProductDetail = () => {
  const { id } = useParams();
  const product = data.find((p) => p.id === parseInt(id));

  return (
    <div
      id="product-detail"
      className=" w-full flex items-center justify-center py-10 h-auto min-h-screen"
    >
      <div></div>
      <div
        id="product-detail-container"
        className=" w-full flex items-center justify-center px-4 lg:px-0"
      >
        {product ? (
          <div
            id="detail-card"
            className=" flex flex-col lg:flex-row items-center justify-around w-full lg:w-[80%]  shadow-lg p-4 lg:p-8 gap-10"
          >
            <div className=" w-full lg:w-[80%] flex items-center justify-center">
              <img
                src={product.image}
                alt={product.name}
                className=" w-full h-full object-contain"
              />
            </div>
            <div className=" flex flex-col items-start justify-start gap-6 lg:gap-10 w-full lg:w-[80%] lg:h-[60vh] pt-4 px-4 lg:pt-0 overflow-y-auto">
              <span className=" text-2xl lg:text-4xl font-bold text-center lg:text-left">
                {product.name}
              </span>
              <span className=" text-gray-700 line-through text-lg lg:text-xl">
                {product.cutPrice}
              </span>
              <span className=" text-red-700 text-lg lg:text-xl">
                {product.price}
              </span>
              <span className=" text-xl font-semibold ">
                To Buy Press WhatsApp Button
              </span>
              <Link
                to={`https://wa.me/923066917325?text=Hello, %0AI'm interested in this product: %0A${product.name}, %0APrice: ${product.price}, %0AColor: ${product.color}`}
                target="_blank"
                className=" py-3 w-full flex items-center justify-center gap-2 bg-green-500 text-base lg:text-lg font-semibold hover:bg-green-800 rounded-lg"
              >
                <FaWhatsapp />
                <span>WhatsApp</span>
              </Link>
              <span className=" text-base lg:text-lg font-medium flex flex-col gap-4 lg:gap-6">
                <p
                  dangerouslySetInnerHTML={{
                    __html: product.description,
                  }}
                />
                <p>
                  Suit Cutting: 4 meters <br /> Guarantee of Color Fade <br />{" "}
                  Guarantee of Burr <br /> Return Policy
                </p>
                <p className=" text-center lg:text-left">
                  Free Shipping Nationwide
                </p>
              </span>
            </div>
          </div>
        ) : (
          <p>Product not found</p>
        )}
      </div>
    </div>
  );
};

export default ProductDetail;
