import React from "react";
import { FaHandHoldingHeart, FaMoneyBill1Wave } from "react-icons/fa6";
import { LiaShippingFastSolid } from "react-icons/lia";

export default function WhyUs() {
  return (
    <div
      id="why-us"
      className="w-full flex items-center justify-center flex-col"
    >
      <div
        id="why-us-heading-container"
        className="w-full justify-center items-center flex text-4xl text-centertext-center font-semibold py-4 mt-10"
      >
        <span id="why-us-heading">WHY US?</span>
      </div>
      <div
        id="services-container"
        className="flex items-center justify-center gap-20 flex-wrap"
      >
        <div
          id="first-service"
          className="flex items-center justify-center gap-4 flex-col h-80 w-96"
        >
          <span>
            <LiaShippingFastSolid size={60} />
          </span>
          <span className="font-semibold text-4xl text-center">
            Free Shipping
          </span>
          <span className="text-base ">Free Shipping On All Orders</span>
        </div>
        <div
          id="second-service"
          className="flex items-center justify-center gap-4 flex-col h-80 w-96"
        >
          <span>
            <FaHandHoldingHeart size={60} />
          </span>
          <span className="font-semibold text-4xl text-center">
            Free Return
          </span>
          <span className="text-base ">Cash On Delivery</span>
        </div>
        <div
          id="third-service"
          className="flex items-center justify-center gap-4 flex-col h-80 w-96"
        >
          <span>
            {" "}
            <FaMoneyBill1Wave size={60} />
          </span>
          <span className="font-semibold text-4xl text-center">
            Money Back Guarantee
          </span>
          <span className="text-base ">We Support Online 24 Hours</span>
        </div>
      </div>
    </div>
  );
}
