import React from "react";
import { useNavigate } from "react-router-dom";
import { data } from "../Data/Data";

export default function JBoski() {const navigate = useNavigate();

const handleProductClick = (id) => {
  navigate(`/product/${id}`);
};

  return (
    <div id="j-boski">
      <div
        id="j-boski-heading-container"
        className="w-full flex items-center justify-center max-[768px]:text-4xl text-5xl py-8 font-bold"
      >
        <span className="text-center">J.BOSKI</span>
      </div>
      <div
        id="j-boski-container"
        className="w-full flex items-center justify-center gap-10 flex-wrap py-10"
      >
        {data.map((product) => {
          if (product.type === "j-boski") {
            return (
              <div
                key={product.id}
                onClick={() => handleProductClick(product.id)}
                style={{ cursor: "pointer" }}
                id="home-card"
                className="flex items-center justify-start flex-col  max-[768px]:w-80 shadow  h-96  gap-4 w-64 transition delay-75 duration-200 ease-in-out hover:scale-110"
              >
                <div
                  id="product-image"
                  className="w-full h-[75%] max-[768px]:w-full"
                >
                  <img
                    src={product.image}
                    alt="product-image"
                    className=" h-full w-full"
                  />
                </div>
                <div className="flex justify-center items-start flex-col text-base w-full h-[25%] px-4">
                  <span className="w-full max-h-full">
                    {product.name} <br /> (PREMIUM FABRIC)
                  </span>
                  <span className="text-red-700">{product.price}</span>
                </div>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
}
