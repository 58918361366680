import "./App.css";
// import Header from "./components/Header";
import Headline from "./components/Headline";
import Navbar from "./components/Navbar";
import ShingaiBoski from "./components/ShingaiBoski";
import TurkishBoski from "./components/TurkishBoski";
import JTikTokAddition from "./components/JTikTokAddition";
import BinFaisalBoski from "./components/BinFaisalBoski";
import BinFaisalWnW from "./components/BinFaisalWnW";
import GulAhmadMens from "./components/GulAhmadMens";
import Grace from "./components/Grace";
import AllInOne from "./components/AllInOne";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import GraceWnW from "./components/GraceWnW";
import JBoski from "./components/JBoski";
import JItalianSilk from "./components/JItalianSilk";
import Home from "./components/Home";
import Footer from "./components/Footer";
import ProductDetail from "./components/ProductDetail";
import WhyUs from "./components/WhyUs";

function App() {
  return (
    <>
      <BrowserRouter>
        <Headline />
        <Navbar />
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/shingai-boski" element={<ShingaiBoski />} />
            <Route path="/turkish-boski" element={<TurkishBoski />} />
            <Route path="/j-tiktok-addition" element={<JTikTokAddition />} />
            <Route path="/j-boski" element={<JBoski />} />
            <Route path="/j-italian-silk" element={<JItalianSilk />} />
            <Route path="/bin-faisal-boski" element={<BinFaisalBoski />} />
            <Route path="/bin-faisal-wnw" element={<BinFaisalWnW />} />
            <Route path="/gul-ahmad-mens" element={<GulAhmadMens />} />
            <Route path="/grace" element={<Grace />} />
            <Route path="/grace-wnw" element={<GraceWnW />} />
            <Route path="/all-in-one" element={<AllInOne />} />
            <Route path="/product/:id" element={<ProductDetail />} />
          </Routes>
        </main>
        <WhyUs />
        <div className="fixed bottom-0 right-0  w-40 p-4 z-50 ">
          <Link
            to="https://wa.me/923066917325?text=Can i get more details ? "
            target="_blank"
          >
            <img src="../../wlogo.png" alt="whatsapp-logo" />
          </Link>
        </div>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
